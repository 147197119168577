<template>
  <p style="margin-top: 0.5em;">
    <span
      class="has-text-weight-semibold"
      v-html="label + ':'"
    />
    {{ value }}
    <span
      v-if="units"
      v-html="units"
    />
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    units: {
      type: String,
      default: '',
    },
  },
};
</script>
