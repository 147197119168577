<template>
  <div class="upload">
    <simple-modal-new
      :active="helpModalActive"
      title="Mesh Information"
      @action="helpModalActive = false"
      @close="helpModalActive = false"
    >
      <p style="margin-bottom: 2em">
        The valve mesh should be exported as Abaqus input file in .INP format
        <span>(</span>
        <a
          :href="`${publicPath}${exampleFile}`"
          target="_blank"
          rel="noopener noreferrer"
          download
        >download example</a>
        <span>)</span>.
        The model should be oriented according to the coordinate system as indicated in the left figure.
        See example file and right figure for element dimensions assignment.
        All dimensions must be expressed in mm.
        No material property assignment is necessary.
      </p>
      <div class="columns is-vcentered">
        <div class="column">
          <figure class="image">
            <img src="../assets/coordinates.png">
          </figure>
        </div>
        <div class="column">
          <figure class="image">
            <img src="../assets/section.png">
          </figure>
        </div>
      </div>
    </simple-modal-new>
    <label class="label">
      <span
        v-if="status === 'ok' || status === 'error'"
        class="icon is-normal"
        :class="iconClass"
      >
        <font-awesome-icon :icon="icon" />
      </span>
      <Loader
        v-else-if="status === 'validating'"
        small
        :is-loading="true"
      />
      {{ label }}
      <FieldHelp :text="help" />
    </label>
    <div class="file has-name is-fullwidth">
      <label class="file-label">
        <input
          :id="id"
          :ref="refName"
          class="file-input"
          :name="refName"
          type="file"
          @change="handleFileUpload()"
        >
        <span class="file-cta">
          <span class="file-icon">
            <font-awesome-icon icon="upload" />
          </span>
          <span class="file-label">Choose a file…</span>
        </span>
        <span class="file-name my-file-name">{{ filename }}</span>
      </label>
    </div>
    <p class="help is-large with-space">
      Accepts Abaqus .INP format.
      <a
        href="#"
        @click="helpModalActive = true"
      >
        Click here to show mesh information.
      </a>
    </p>
  </div>
</template>

<script>
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import SimpleModalNew from './SimpleModalNew.vue';

function validateFile(allowedFileExtensions, file) {
  const forbiddenFilename = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$|([<>:"/\\|?*])|(\.|\s)$/ig;
  if (file) {
    if (file.name !== '') {
      if (forbiddenFilename.test(file.name)) {
        throw new Error(`Invalid filename \`${file.name}\` for upload.`);
      }
      const extension = file.name.toLowerCase().split('.').pop();
      if (!allowedFileExtensions.includes(extension)) {
        throw new Error(`File of type \`${extension}\` not accepted. Must be one of ${allowedFileExtensions.join(', ')}.`);
      }
    } else {
      throw new Error('Filename empty.');
    }
  } else {
    throw new Error('File not found.');
  }
}

export default {
  components: {
    FieldHelp,
    Loader,
    SimpleModalNew,
  },
  props: {
    allowedFileExtensions: Array,
    exampleFile: String,
    help: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'upload-default-id',
    },
    label: {
      type: String,
      default: 'Upload',
    },
    refName: {
      type: String,
      default: 'unique-reference',
    },
    modelValue: Object,
  },

  emits: ['update:modelValue'],
  data() {
    return {
      helpModalActive: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    icon() {
      return this.modelValue.status === 'ok' ? 'check' : 'times';
    },
    iconClass() {
      return this.modelValue.status === 'ok' ? 'has-text-success' : 'has-text-danger';
    },
    status() {
      if (this.modelValue == null) return '';
      return this.modelValue.status;
    },
    filename() {
      if (this.modelValue == null) return '';
      return this.modelValue.filename;
    },
  },
  methods: {
    async handleFileUpload() {
      const [file] = this.$refs[this.refName].files;
      const modelUpdate = {
        filename: file.name,
        status: 'validating',
        contents: await file.text(),
      };
      this.$emit('update:modelValue', modelUpdate);
      try {
        validateFile(this.allowedFileExtensions, file);
        modelUpdate.status = 'ok';
        this.$emit('update:modelValue', modelUpdate);
      } catch (error) {
        modelUpdate.status = 'error';
        this.$emit('update:modelValue', modelUpdate);
        this.$store.dispatch('modal/error', error);
      }
    },
  },
};
</script>

<style scoped>
div.upload {
  margin-top: 0.5em;
}
</style>
