import core from 'ist-skeleton-vue';
import apiConfig from './config/api-config';
import apolloConfig from './config/apollo-config';
import iamConfig from './config/iam-config';
import productConfig from './config/product-config';
import supportConfig from './config/support-config';
import Simulation from './views/Simulation.vue';
import Results from './views/Results.vue';
import Home from './views/Home.vue';

const globalConfig = {
  api: apiConfig,
  apollo: apolloConfig,
  iam: iamConfig,
  product: productConfig,
  router: [
    {
      path: '/jobs/new',
      name: 'simulation',
      component: Simulation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/jobs/:id',
      name: 'job',
      component: Results,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  support: supportConfig,
};

// App Setup
const app = core.setup(globalConfig);

// Mount App
app.mount('#app');
