<template>
  <div class="section">
    <div class="container ist-container">
      <h1 class="title">
        New Simulation
      </h1>
      <StepIndicator
        v-model="activeStep"
        :labels="stepLabels"
        :warnings="stepWarnings"
      />
      <!-- step 0 -->
      <div v-if="activeStep === 0">
        <h2 class="title is-4">
          Setup
        </h2>
        <p class="with-space readable has-text-justified">SEV Radial Force Test simulates the characterization of radial resistive force and chronic outward force in self-expandible heart valve prostheses, following ISO standard 5840-3.</p>
        <p class="with-space readable has-text-justified">The tool enables simulations of radial forces in various types of self-expandable heart valves made in Nitinol, such as transcatheter aortic valve and transcatheter mitral valve. The tool can be used to test different valve frame designs, and results can be downloaded in a report following FDA guidelines.</p>
        <div class="columns">
          <div class="column is-6">
            <Field
              id="s0-title"
              v-model="title"
              label="Title"
              :validate="highestVisitedStep > 0"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step 1 -->
      <div v-if="activeStep === 1">
        <h2 class="title is-4">
          Device
        </h2>
        <div
          class="control"
          style="margin-bottom: 1em;"
        >
          <label class="radio">
            <input
              type="radio"
              name="uploadmesh"
              style="margin-right: 0.25em;"
              checked
            >
            Mesh Upload
          </label>
        </div>
        <div
          class="control"
          style="margin-bottom: 1em;"
        >
          <label class="radio">
            <input
              type="radio"
              name="uploadmesh"
              style="margin-right: 0.25em;"
              disabled
            >
            <span class="has-text-grey">
              Geometry Upload --
              <span class="is-italic">
                <span class="has-text-black">
                  If you are interested in uploading a geometry file
                </span>
                <a
                  class="is-primary"
                  style="text-decoration: underline;"
                  :href="wrikeLink"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  get in touch!
                </a>
              </span>
            </span>
          </label>
        </div>
        <!-- TODO select for constitutive_model (umat, super_elasticity) -->
        <!-- TODO radio for mesh and geometry (disabled) -->
        <div class="columns" style="margin-top: 1em;">
          <div class="column is-6">
            <UploadFile
              id="meshupload"
              v-model="meshUpload"
              :allowed-file-extensions="meshFileExtensions"
              ref-name="meshFile"
              label="Mesh Upload"
              example-file="example_mesh.inp"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step 2 -->
      <div v-if="activeStep === 2">
        <h2 class="title is-4">
          Material
        </h2>
        <p class="with-space readable has-text-justified">
          Device material: Nitinol with superelastic behavior
          <FieldHelp text="The super-elastic material behaviour of Nitinol implemented in Abaqus is based on the model developed by Auricchio et al. (1997). Austenite is assumed to be stable for temperature higher than the Austenite finish temperature (Af). The test is performed at constant temperature set at 37°C. Upon loading the material in the austenite phase starts transforming into martensite beyond a certain stress. When unloaded, the martensite transforms into austenite (see Figure)." />
        </p>
        <!-- TODO button "Show the implemented superelastic behavior -->
        <div class="columns">
          <div class="column is-6">
            <p class="with-space readable has-text-justified">Default material parameters describing Nitinol superelastic behavior are inserted based on Morganti et al., 2016. Values can be modified according to the experimental data available to the user.</p>
            <Field
              v-for="element in materialsGroupOne"
              :id="element.id"
              :key="element.id"
              v-model="element.model"
              :label="element.label"
              :placeholder="element.placeholder"
              :help="element.help"
              :vmin="element.vminSigmaEL ? vminSigmaEL : element.vminSigmaSU ? vminSigmaSU : element.vmin"
              :vmax="element.vmaxSigmaSL ? vmaxSigmaSL: element.vmaxSigmaEU ? vmaxSigmaEU : element.vmax"
              :vinteger="element.type === 'integer'"
            />
          </div>
          <div class="column has-text-centered">
            <figure
              class="image"
            >
              <img src="../assets/csuperelasonly-uniaxial-nls.png">
            </figure>
            <p class="has-text-left" style="margin-top: 0.5em;">Uniaxial response of Nitinol superelastic material  [SIMULIA Abaqus RELEASE 2022, Laura Cuzner (Release Manager), John Pantaleo (R&D Director). November 2021]</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <Field
              v-for="element in materialsGroupTwo"
              :id="element.id"
              :key="element.id"
              v-model="element.model"
              :label="element.label"
              :placeholder="element.placeholder"
              :help="element.help"
              :vmin="element.vminSigmaEL ? vminSigmaEL : element.vminSigmaSU ? vminSigmaSU : element.vmin"
              :vmax="element.vmaxSigmaSL ? vmaxSigmaSL: element.vmaxSigmaEU ? vmaxSigmaEU : element.vmax"
              :vinteger="element.type === 'integer'"
            />
          </div>
          <div class="column">
            <Field
              v-for="element in materialsGroupThree"
              :id="element.id"
              :key="element.id"
              v-model="element.model"
              :label="element.label"
              :placeholder="element.placeholder"
              :help="element.help"
              :vmin="element.vminSigmaEL ? vminSigmaEL : element.vminSigmaSU ? vminSigmaSU : element.vmin"
              :vmax="element.vmaxSigmaSL ? vmaxSigmaSL: element.vmaxSigmaEU ? vmaxSigmaEU : element.vmax"
              :vinteger="element.type === 'integer'"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step 3 -->
      <div v-if="activeStep === 3">
        <h2 class="title is-4">
          Test
        </h2>
        <div class="columns">
          <!-- TODO button "Show crimping procedure -->
          <div class="column is-6">
            <p class="with-space readable has-text-justified">The crimping tool is modelled as a rigid cylinder using surface Abaqus elements (SFM3D4R elements). The simulation is performed on a complete crimping cycle and is divided in two steps. In the first step, radial displacements are assigned to the nodes of the cylinder, reducing the cylinder diameter from the initial value up to the minimum diameter. Then, in the second step radial displacements are assigned to the cylinder to retrieve it to the initial diameter.</p>
            <p class="with-space readable has-text-justified">The default crimping diameter value is 6mm (18 Fr), testing the suitability for a 18 Fr hemostatic vessel introducer sheath.</p>
            <Field
              v-for="element in test"
              :id="element.id"
              :key="element.id"
              v-model="element.model"
              :label="element.label"
              :placeholder="element.placeholder"
              :help="element.help"
              :vinteger="element.type === 'integer'"
            />
          </div>
          <div class="column">
            <figure
              class="image"
              style="max-width: 80%"
            >
              <img src="../assets/TAVI_optimised.gif">
            </figure>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step 4 -->
      <div v-if="activeStep === 4">
        <h2 class="title is-4">
          Summary
        </h2>
        <SummaryItem
          label="Title"
          :item="title"
        />
        <p :class="{ 'has-text-warning': meshUpload !== null && meshUpload.status !== 'ok' }">
          <span class="has-text-weight-bold">File name:&nbsp;</span>
          <span
            v-if="meshUpload !== null"
            id="UploadFilename"
          >{{ meshUpload.filename }}</span>
        </p>
        <p :class="{ 'has-text-warning': stepWarnings[2] }">
          <span class="has-text-weight-bold">Material table:</span>
        </p>
        <div class="table-container">
          <table
            id="SummaryTable"
            class="ist-table with-margin"
          >
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="element in material"
                :key="element.id"
              >
                <td><span v-html="element.label" /></td>
                <td>{{ element.model.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p :class="{ 'has-text-warning': stepWarnings[3] }">
          <span class="has-text-weight-bold">Test table:</span>
        </p>
        <div class="table-container">
          <table
            id="TestTable"
            class="ist-table with-margin"
          >
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="element in test"
                :key="element.id"
              >
                <td><span v-html="element.label" /></td>
                <td>{{ element.model.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          style="margin-top: 2em"
          data-cy="tokens"
        >
          The simulation will consume {{ tokenCounter }}
          <span>token</span>
          <span v-if="tokenCounter > 1">s</span>.
          <span
            class="tag is-medium space-left"
            :class="{ 'is-warning': tokenCounter > tokensAvailable }"
          >{{ tokensAvailable }} tokens available</span>
        </p>
        <p v-if="tokenCounter > tokensAvailable">
          Not enough available tokens
        </p>
        <p v-if="!jobValidates">
          Provide missing input.
        </p>
        <button
          id="s3-submit"
          class="button run-button"
          :class="{
            'is-warning': !allowedToSubmit,
            'is-success': allowedToSubmit,
          }"
          :disabled="isSubmitting || !allowedToSubmit"
          @click="submitJob"
        >
          <Loader :is-loading="isSubmitting" />Run Simulation
        </button>
        <StepButtons
          v-model="activeStep"
          is-last
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Field from 'ist-skeleton-vue/src/components/Simulation/Field.vue';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import StepButtons from 'ist-skeleton-vue/src/components/Simulation/StepButtons.vue';
import StepIndicator from 'ist-skeleton-vue/src/components/Simulation/StepIndicator.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';
import UploadFile from '../components/UploadFileNew.vue';

// TODO upload file (not csv)

export default {
  components: {
    Field,
    FieldHelp,
    Loader,
    StepButtons,
    StepIndicator,
    SummaryItem,
    UploadFile,
  },
  data() {
    return {
      wrikeLink: 'https://www.wrike.com/form/eyJhY2NvdW50SWQiOjI0Mjk1ODQsInRhc2tGb3JtSWQiOjU5OTgwOX0JNDc5MjA1ODk3Njg1NAk1NTZhODc3ZTA4ZTJkNDVhMzBjNzk2NTc0ZDc1N2UzZmZmNmI4N2RkNjFlMTNjMDg5ZGUxZDFiYWY4MzM1MjQ1',
      activeStep: 0,
      highestVisitedStep: 0, // up to which step to run validation
      stepLabels: ['Setup', 'Device', 'Material', 'Test', 'Run'],
      // tokens
      tokensAvailable: 0,
      tokenCounter: 0,
      // form status
      isSubmitting: false,
      jobValidates: false,
      stepWarnings: [false, false, false, false, false],
      // user data below
      title: { value: '', ok: false },
      meshUpload: null,
      meshFileExtensions: ['inp'],
      material: [
        {
          id: 'dens',
          label: 'Density (g/cm<sup>3</sup>)',
          type: 'float',
          model: { value: '6.5', ok: true },
          placeholder: '6.5',
          vmin: 6,
          vmax: 7,
        },
        {
          id: 'E_A',
          label: 'Austenite elasticity E<sub>A</sub> (MPa)',
          type: 'integer',
          model: { value: '51700', ok: true },
          placeholder: '51700',
          vmin: 20000,
          vmax: 100000,
        },
        {
          id: 'v_A',
          label: 'Austenite Poisson\'s ratio ν<sub>A</sub> (-)',
          type: 'float',
          model: { value: '0.3', ok: true },
          placeholder: '0.3',
          vmin: 0.1,
          vmax: 0.45,
        },
        {
          id: 'E_M',
          label: 'Martensite elasticity E<sub>M</sub> (MPa)',
          type: 'integer',
          model: { value: '47800', ok: true },
          placeholder: '47800',
          vmin: 15000,
          vmax: 70000,
        },
        {
          id: 'v_M',
          label: 'Martensite Poisson\'s ratio ν<sub>M</sub> (-)',
          type: 'float',
          model: { value: '0.3', ok: true },
          placeholder: '0.3',
          vmin: 0.1,
          vmax: 0.45,
        },
        {
          id: 'epsilon_L',
          label: 'Transformation strain ε<sup>L</sup> (-)',
          type: 'float',
          model: { value: '0.063', ok: true },
          placeholder: '0.063',
          vmin: 0.02,
          vmax: 0.08,
        },
        {
          id: 'loading',
          label: 'δσ/δT loading (MPa/K)',
          type: 'float',
          model: { value: '2.1', ok: true },
          placeholder: '2.1',
          vmin: 1,
          vmax: 10,
        },
        {
          id: 'sigma_SL',
          label: 'Start of transformation loading σ<span class="supsub"><sup>S</sup><sub>L</sub></span> (MPa)',
          help: 'Value must be in range of 100 to 800, and smaller than σEL',
          type: 'integer',
          model: { value: '600', ok: true },
          placeholder: '600',
          vmaxSigmaSL: true,
          vmin: 100,
          vmax: 800,
        },
        {
          id: 'sigma_EL',
          label: 'End of transformation loading σ<span class="supsub"><sup>E</sup><sub>L</sub></span> (MPa)',
          help: 'Value must be in range of 200 to 900, and greater than σSL',
          type: 'integer',
          model: { value: '670', ok: true },
          placeholder: '670',
          vminSigmaEL: true,
          vmin: 200,
          vmax: 900,
        },
        {
          id: 'unloading',
          label: 'δσ/δT unloading (MPa/K)',
          type: 'float',
          model: { value: '2.1', ok: true },
          placeholder: '2.1',
          vmin: 1,
          vmax: 10,
        },
        {
          id: 'sigma_SU',
          label: 'Start of transformation unloading σ<span class="supsub"><sup>S</sup><sub>U</sub></span> (MPa)',
          help: 'Value must be in range of 100 to 500, and greater than σEU',
          type: 'integer',
          model: { value: '288', ok: true },
          placeholder: '288',
          vminSigmaSU: true,
          vmin: 100,
          vmax: 500,
        },
        {
          id: 'sigma_EU',
          label: 'End of transformation unloading σ<span class="supsub"><sup>E</sup><sub>U</sub></span> (MPa)',
          help: 'Value must be in range of 1 to 400, and smaller than σSU',
          type: 'integer',
          model: { value: '254', ok: true },
          placeholder: '254',
          vmaxSigmaEU: true,
          vmin: 1,
          vmax: 400,
        },
      ],
      materialSigmaSL: null,
      materialSigmaEL: null,
      materialSigmaSU: null,
      materialSigmaEU: null,
      test: [
        {
          id: 'crimp_D',
          label: 'Crimping diameter (mm)',
          help: 'The crimping diameter is the minimum diameter to which the valve is radially compressed.',
          type: 'float',
          model: { value: '6', ok: true },
          placeholder: '6',
        },
      ],
      fixed: [
        {
          id: 'constitutive_model',
          value: 'super_elasticity',
        },
        {
          id: 'cpus',
          value: 2,
        },
        {
          id: 'temp',
          value: 37,
        },
        {
          id: 'sigma_SCL',
          value: 495,
        },
        /* {
          id: 'epsilon_L_V',
          value: 0.0455,
        }, */
      ],
    };
  },
  computed: {
    materialsGroupOne() {
      return this.material.filter((el, index) => index < 6);
    },
    materialsGroupTwo() {
      return this.material.filter((el, index) => index >= 6 && index < 9);
    },
    materialsGroupThree() {
      return this.material.filter((el, index) => index >= 9);
    },
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    vminSigmaEL() {
      return this.calculateLimit(this.materialSigmaEL.vmin, this.materialSigmaEL.vmax, Number(this.materialSigmaSL.model.value));
    },
    vmaxSigmaSL() {
      return this.calculateLimit(this.materialSigmaSL.vmin, this.materialSigmaSL.vmax, Number(this.materialSigmaEL.model.value));
    },
    vminSigmaSU() {
      return this.calculateLimit(this.materialSigmaSU.vmin, this.materialSigmaSU.vmax, Number(this.materialSigmaEU.model.value));
    },
    vmaxSigmaEU() {
      return this.calculateLimit(this.materialSigmaEU.vmin, this.materialSigmaEU.vmax, Number(this.materialSigmaSU.model.value));
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep > this.highestVisitedStep) this.highestVisitedStep = newStep;
      const numberSteps = this.stepLabels.length;
      if (newStep === numberSteps - 1) this.validateJob();
    },
  },
  mounted() {
    this.fetchTokens();
    this.materialRef();
  },
  methods: {
    materialRef() {
      this.material.forEach((element) => {
        if (element.id === 'sigma_SL') {
          this.materialSigmaSL = element;
        } else if (element.id === 'sigma_EL') {
          this.materialSigmaEL = element;
        } else if (element.id === 'sigma_SU') {
          this.materialSigmaSU = element;
        } else if (element.id === 'sigma_EU') {
          this.materialSigmaEU = element;
        }
      });
    },
    calculateLimit(min, max, ref) {
      if (ref < min) {
        return min;
      } if (ref > max) {
        return max;
      }
      return ref;
    },
    parseValue(element) {
      let value = null;
      switch (element.type) {
        case 'float':
          value = parseFloat(element.model.value);
          break;
        case 'integer':
          value = parseInt(element.model.value, 10);
          break;
        default:
          value = element.model.value;
          break;
      }
      return value;
    },
    async submitJob() {
      this.isSubmitting = true;
      try {
        const inputData = {};
        this.material.forEach((element) => {
          inputData[element.id] = this.parseValue(element);
        });
        this.test.forEach((element) => {
          inputData[element.id] = this.parseValue(element);
        });
        this.fixed.forEach((element) => {
          inputData[element.id] = element.value;
        });
        // add uploaded file
        inputData.mesh_contents = this.meshUpload.contents;
        inputData.file_mesh = this.meshUpload.filename;

        if (window.Cypress) {
          // During E2E testing, input data is saved to global window object for retrieval.
          window.testSimulationInputs = inputData;
        }

        await this.$apollo.mutate({
          mutation: gql`
          mutation addSevJobAndTasks($product_name: ProductName!, $tasks: [SevTaskIn]!, $job_name: String!) {
            job: addSevJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              product_name
            }
          }`,
          variables: {
            product_name: this.$product.id,
            tasks: [inputData],
            job_name: this.title.value,
          },
        });
        this.$router.push('/home');
      } catch (error) {
        console.error(error);
      }
      this.isSubmitting = false;
    },
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    validateJob() {
      let valid = true;
      const numberSteps = this.stepLabels.length;
      const stepWarnings = Array(numberSteps).fill(false);
      const tokens = 1;

      // validate step 0
      if (!this.title.ok) {
        valid = false;
        stepWarnings[0] = true;
      }

      // validate step 1
      if (this.meshUpload == null || this.meshUpload.status !== 'ok') {
        valid = false;
        stepWarnings[1] = true;
      }

      // validate step 2
      this.material.forEach((element) => {
        if (!element.model.ok) {
          valid = false;
          stepWarnings[2] = true;
        }
      });

      // validate step 3
      this.test.forEach((element) => {
        if (!element.model.ok) {
          valid = false;
          stepWarnings[3] = true;
        }
      });

      this.jobValidates = valid;
      // TODO tarocco
      this.jobValidates = true;
      this.stepWarnings = stepWarnings;
      this.tokenCounter = tokens;
    },
  },
};
</script>

<style>
.supsub {
  position: relative;
}
.supsub > :first-child {
  position: absolute;
}
p.readable {
  max-width: 65em;
  margin-bottom: 1em;
}
button.run-button {
  margin-top: 1em;
}
table.with-margin {
  margin-top: 0.75em;
  margin-bottom: 1.25em;
}
.space-left {
  margin-left: 0.5em;
}
</style>
