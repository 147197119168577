<template>
  <div class="card">
    <header
      class="card-header"
      style="background-color: rgb(167, 229, 255);"
    >
      <p class="card-header-title">
        Strain
      </p>
    </header>
    <div class="card-content is-flex nicely-positioned">
      <button
        class="button is-primary"
        :disabled="currentPage <= 1"
        @click="goPrevious"
      >
        <font-awesome-icon icon="chevron-left" />
      </button>
      <figure class="image">
        <img
          :id="id"
          class="my-image"
          :src="currentSrc"
        >
      </figure>
      <button
        class="button is-info"
        :disabled="currentPage >= numberImages"
        @click="goNext"
      >
        <font-awesome-icon icon="chevron-right" />
      </button>
    </div>
    <div class="card-footer has-background-white-bis">
      <div class="card-footer-item">
        <div class="select">
          <select
            :value="step"
            @change="onChange"
          >
            <option
              v-for="(opt, index) in steps"
              :key="index"
              :value="index"
            >
              {{ opt }}
            </option>
          </select>
        </div>
      </div>
      <div class="card-footer-item">
        <p class="control has-icons-left">
          <input
            v-model="strPage"
            type="text"
            class="input"
            :class="{'is-warning': !validPageInput}"
            size="4"
            maxlength="4"
          >
          <span class="icon is-small is-left">
            <font-awesome-icon icon="search" />
          </span>
        </p>
      </div>
      <div class="card-footer-item">
        <nav
          class="pagination"
          role="navigation"
          aria-label="pagination"
        >
          <ul class="pagination-list">
            <li>
              <a
                class="pagination-link has-background-white"
                aria-label="go to first image"
                @click="goPage(1)"
              >1</a>
            </li>
            <li>
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a
                class="pagination-link has-background-white"
                aria-label="go to last image"
                @click="goPage(numberImages)"
              >{{ numberImages }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    images: Array,
  },
  data() {
    return {
      step: 0,
      steps: ['All steps', 'Compression', 'Expansion'],
      currentIndex: 0,
      strPage: '1',
      debouncing: false,
      validPageInput: true,
    };
  },
  computed: {
    filteredImages() {
      if (Number(this.step) === 1) return this.images.filter((img) => img.includes('frame_1'));
      if (Number(this.step) === 2) return this.images.filter((img) => img.includes('frame_2'));
      return this.images;
    },
    numberImages() {
      return this.filteredImages.length;
    },
    currentSrc() {
      return this.filteredImages[this.currentIndex];
    },
    currentPage() {
      return this.currentIndex + 1; // conversion to 1-based index
    },
    nextPage() {
      const nextImage = this.currentIndex + 1;
      if (nextImage < this.numberImages) {
        const nextPage = nextImage + 1;
        return nextPage;
      }
      return 0;
    },
    previousPage() {
      const previousImage = this.currentIndex - 1;
      if (previousImage >= 0) {
        const previousPage = previousImage + 1;
        return previousPage;
      }
      return -1;
    },
  },
  watch: {
    strPage() {
      // eslint-disable-next-line eqeqeq
      if (this.strPage == this.currentPage) {
        this.validPageInput = true;
        return;
      }
      const debounceMilliSeconds = 300;
      if (!this.debouncing) {
        setTimeout(() => {
          this.maybeGoPage();
          this.debouncing = false;
        }, debounceMilliSeconds);
      }
      this.debouncing = true;
    },
    step() {
      this.goPage(1);
    },
  },
  methods: {
    goNext() {
      if (this.currentPage < this.numberImages) {
        this.goPage(this.currentPage + 1);
      }
    },
    goPrevious() {
      if (this.currentPage > 1) {
        this.goPage(this.currentPage - 1);
      }
    },
    goPage(page) {
      this.validPageInput = true;
      this.strPage = page;
      this.currentIndex = page - 1;
    },
    maybeGoPage() {
      const page = parseInt(this.strPage, 10);
      if (!Number.isNaN(page) && page >= 1 && page <= this.numberImages && page !== this.currentPage) {
        this.goPage(page);
      } else {
        this.validPageInput = false;
      }
    },
    onChange(event) {
      this.step = event.target.value;
    },
  },
};
</script>

<style>
.nicely-positioned {
  align-items: center;
  justify-content: space-between;
}
ul {
  list-style:none;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: black !important;
}
</style>
