<template>
  <div class="documentation">
    <h1 class="title">
      Documentation
    </h1>
    <p>
      SEV Radial Force Test is based on a mechanical model representing a self-expandable valve (Carbonaro et al, 2021) made of Nitinol (Auricchio et al 1997, Auricchio et al 1997, Morganti et al, 2016) compressed by a rigid cylinder (Cabrera et al 2017), in a two-step simulation following ISO standard (ISO 5840-3:2021).
    </p>
    <div class="with-space">
      <h2 class="title is-4">
        Publications
      </h2>
      <ul>
        <li class="with-space">
          In silico biomechanical design of the metal frame of transcatheter aortic valves: multi-objective shape and cross-sectional size optimization
          <br>
          Carbonaro D, Gallo D, Morbiducci U, et al.
          Struct Multidisc Optim <b>2021</b>
          <br>
          DOI: 10.1007/s00158-021-02944-w
        </li>
        <li class="with-space">
          Cardiovascular implants - Cardiac valve prostheses - Part 3: Heart valve substitutes implanted by transcatheter techniques
          <br>
          ISO Standard 5840-3 <b>2021</b>
        </li>
        <li class="with-space">
          Understanding the requirements of self-expandable stents for heart valve replacement: Radial force, hoop force and equilibrium
          <br>
          Cabrera M, Oomens C, Baaijens F
          J Mech Behav Biomed Mater <b>2017</b>
          <br>
          DOI: 10.1016/j.jmbbm.2017.02.006
        </li>
        <li class="with-space">
          Shape-memory alloys: macromodelling and numerical simulations of the superelastic behavior
          <br>
          Auricchio F, Taylor R, Lubliner J.
          Computer Methods in Applied Mechanics and Engineering <b>1997</b>
          <br>
          DOI: 10.1016/S0045-7825(96)01232-7
        </li>
        <li class="with-space">
          Shape-memory alloys: modelling and numerical simulations of the finite-strain superelastic behavior
          <br>
          Auricchio F, Taylor R.
          Computer Methods in Applied Mechanics and Engineering <b>1997</b>
          <br>
          DOI: 10.1016/S0045-7825(96)01147-4
        </li>
        <li class="with-space">
          Prediction of patient-specific post-operative outcomes of TAVI procedure: The impact of the positioning strategy on valve performance
          <br>
          Morganti S, Brambilla N, Petronio AS, et al.
          J Biomech <b>2016</b>
          <br>
          DOI: 10.1016/j.jbiomech.2015.10.048
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
div.documentation {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
