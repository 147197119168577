<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse>
                  <p>
                    SEV Radial Force Test simulates the characterization of radial resistive force and chronic outward force in self-expandible heart valve prostheses, following ISO standard 5840-3.
                  </p>
                  <p style="margin-top: 0.75em;">
                    Detailed information on how to use SEV Radial Force Test is provided in the user manual below.
                  </p>
                  <ul style="margin-top: 0.75em;">
                    <li>
                      <a
                        :href="`${publicPath}SEV_radial_force_test_user_manual.pdf`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >User Manual
                      </a>
                    </li>
                  </ul>
                </HowToUse>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HowToUse from 'ist-skeleton-vue/src/components/Home/HowToUse.vue';
import LatestActivity from 'ist-skeleton-vue/src/components/Home/LatestActivity.vue';
import NewSimulation from 'ist-skeleton-vue/src/components/Home/NewSimulation.vue';
import Support from 'ist-skeleton-vue/src/components/Home/Support.vue';
import Documentation from '../components/Documentation.vue';

export default {
  components: {
    Documentation,
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
};
</script>
