import image from '../assets/product.jpg';

const product = {
  id: 'sev',
  name: 'SEV Radial Force Test',
  title: 'Self-Expandable Valve (SEV) Radial Force Test',
  subtitle: 'Simulation of the radial force in self-expandable valves following ISO 5840-3',
  description: 'A tool that simulates the radial compression and re-expansion of a self-expandable heart valve (SEV) prosthesis. The simulation aims to characterize the Radial Resistive Force (RRF) and Chronic Outward Force (COF) following ISO standard 5840-3.',
  collaboration: 'SEV Radial Force Test is the result of the collaboration between Politecnico di Torino and InSilicoTrials Technologies.',
  image,
  features: [
    {
      icon: 'clipboard-check',
      title: 'Standard Test',
      description: 'Based on the ISO 5840-3 standard on cardiovascular implants - Heart valve substitutes implanted by transcatheter techniques',
    },
    {
      icon: 'download',
      title: 'Report for Regulatory Submission',
      description: 'Creation of a result report following the FDA guidelines for regulatory submissions',
    },
    {
      icon: 'thumbs-up',
      title: 'Easy to Use',
      description: 'Well-designed wizard that guides through the setup of the computation step by step',
    },
  ],
};

export default product;
