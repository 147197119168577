<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title">
          <Loader :is-loading="true" />
          Results
        </h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName
            :name="job.name"
            @jobNameEdit="handleJobNameEdit"
          />
          <ReportModal
            v-if="job.status == 'completed' && task.status === 'success'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </div>
        <h1
          class="title is-4 no-space-bottom"
          data-cy="job-title"
        >
          {{ job.name }}
        </h1>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ $filters.formatDate(task.started_at) }}</p>
            </div>
            <div class="level-item">
              <p>completed: {{ $filters.formatDate(task.finished_at) }}</p>
            </div>
            <div class="level-item">
              <span
                class="tag is-medium"
                :class="{ 'is-warning': task.status !== 'success' }"
                data-cy="task-status"
              >{{ task.status }}</span>
            </div>
          </div>
        </div>
        <InputsSummary>
          <SummaryItem
            v-if="input.file_mesh !== ''"
            id="FileMesh"
            label="Mesh file name"
            :value="input.file_mesh"
          />
          <SummaryItem
            v-if="output !== null"
            id="nodes"
            label="Number of nodes in the mesh"
            :value="output.valve_nodes"
          />
          <div style="margin-top: 2em; margin-bottom: 1em;">
            <p>
              <span class="has-text-weight-semibold">Material table:</span>
            </p>
            <div style="margin-top: 1em; margin-bottom: 2em;">
              <table
                id="MaterialTable"
                class="table ist-table"
                label="Material Table"
              >
                <thead>
                  <tr>
                    <th class="left">
                      Parameter
                    </th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="left">
                      Density (g/cm<sup>3</sup>)
                    </td>
                    <td>
                      {{ input.dens }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      E<sub>A</sub> - Austenite elasticity (MPa)
                    </td>
                    <td>
                      {{ input.E_A }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      ν<sub>A</sub> - Austenite Poisson's ratio
                    </td>
                    <td>
                      {{ input.v_A }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      E<sub>M</sub> - Martensite elasticity (MPa)
                    </td>
                    <td>
                      {{ input.E_M }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      ν<sub>M</sub> - Martensite Poisson's ratio
                    </td>
                    <td>
                      {{ input.v_M }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      ε<sup>L</sup> - Transformation strain
                    </td>
                    <td>
                      {{ input.epsilon_L }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      δσ/δT loading (MPa/K)
                    </td>
                    <td>
                      {{ input.loading }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      σ<span class="supsub"><sup>S</sup><sub>L</sub></span> - Start of transformation loading (MPa)
                    </td>
                    <td>
                      {{ input.sigma_SL }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      σ<span class="supsub"><sup>E</sup><sub>L</sub></span> - End of transformation loading (MPa)
                    </td>
                    <td>
                      {{ input.sigma_EL }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      δσ/δT unloading (MPa/K)
                    </td>
                    <td>
                      {{ input.unloading }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      σ<span class="supsub"><sup>S</sup><sub>U</sub></span> - Start of transformation unloading (MPa)
                    </td>
                    <td>
                      {{ input.sigma_SU }}
                    </td>
                  </tr>
                  <tr>
                    <td class="left">
                      σ<span class="supsub"><sup>E</sup><sub>U</sub></span> - End of transformation unloading (MPa)
                    </td>
                    <td>
                      {{ input.sigma_EU }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <span class="has-text-weight-semibold">Test table:</span>
            </p>
            <div style="margin-top: 1em; margin-bottom: 2em;">
              <table
                id="TestTable"
                class="table ist-table"
              >
                <thead>
                  <tr>
                    <th class="left">
                      Parameter
                    </th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="left">
                      Crimping diameter (mm)
                    </td>
                    <td>
                      {{ input.crimp_D }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr>
        </InputsSummary>
        <template v-if="resultsReady">
          <h3
            class="title is-4"
            style="margin-top: 1em; padding-bottom: 0;"
          >
            Radial Resistive Force and Chronic Outward Force
          </h3>
          <p class="readable has-text-justified">The graph depicts the Radial Resistive Force (in blue) and Chronic Outward Force (in yellow) as a function of the cylinder diameter size. Results can be downloaded in .csv format.</p>
          <figure class="image figure-with-space">
            <img
              :src="resultsURL('hysteresis.png')"
            >
          </figure>
          <p
            id="MaxForce"
            style="margin-top: 1em; margin-bottom: 2em;"
          >
            <span
              class="has-text-weight-semibold"
            >
              Maximum Force (N):&nbsp;
            </span>
            {{ maxForce }}
          </p>
          <p
            class="buttons"
            style="margin-top: 2em;"
          >
            <a
              class="button is-primary"
              style="margin-right: 0.25em;"
              :href="resultsURL('rf.csv')"
              target="_blank"
              download
            >
              <font-awesome-icon
                class="icon-with-margin"
                icon="download"
              />
              Results
            </a>
          </p>
          <h3
            class="title is-4"
            style="margin-top: 1em; padding-bottom: 0;"
          >
            Valve Strains
          </h3>
          <p class="readable has-text-justified">A color map of max in-plane principal strains are shown on the valve frame. Max. In-Plane Principal is the largest principal value when the absolute value of all in-plane principal values are compared. </p>
          <p class="readable has-text-justified" style="margin-bottom: 2em">You can click on the right and left arrows to increase or decrease the increment, or you can select in the dropdown list the simulation phase (all step, contraction, expansion) you would like to see results.</p>
          <slide-show
            class=""
            id="slideshow"
            :images="frames"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import EditJobName from 'ist-skeleton-vue/src/components/Results/EditJobName.vue';
// import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import InputsSummary from 'ist-skeleton-vue/src/components/Results/InputsSummary.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import ReportModal from 'ist-skeleton-vue/src/components/Results/ReportModal.vue';
import SummaryItem from '@/components/SummaryItem.vue';
import SlideShow from '@/components/SlideShow.vue';

export default {
  components: {
    EditJobName,
    InputsSummary,
    Loader,
    ReportModal,
    SummaryItem,
    SlideShow,
  },
  data() {
    return {
      // job data
      job: null,
      task: null,
      input: null,
      output: null,
      // media data
      baseUrl: null,
      containerSAS: null,
      frames: null,
      // view data
      resultsReady: false,
      // PDF Report
      isPdfLoading: false,
    };
  },
  computed: {
    maxForce() {
      return parseFloat(this.output.maximum).toFixed(3);
    },
  },
  async mounted() {
    try {
      await this.fetchJob();
      await this.fetchMediaInfo();
      if (this.task.status === 'success') {
        await this.fetchOutputJson();
        await this.fetchMedia();
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchJob() {
      const response = await this.$apollo.query({
        query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              status
              created_at
              tasks {
                id: _id
                input
                status
                started_at
                finished_at
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
        },
      });
      this.job = response.data.job;
      [this.task] = this.job.tasks;
      this.input = this.task.input;
    },
    async fetchMediaInfo() {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      this.baseUrl = response.data.info.baseURL;
      this.containerSAS = response.data.info.containerSAS;
    },
    async fetchMedia() {
      const response = await this.$apollo.query({
        query: gql`
          query getMedia($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            media: getMedia(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              contents {
                directory
                files
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      console.log(response.data);
      const resultsDirectory = response.data.media[0].contents.filter((dir) => dir.directory === 'results/snapshots')[0];
      const frames = resultsDirectory.files.filter((file) => file.includes('frame_'));
      const frames1 = resultsDirectory.files.filter((file) => file.includes('frame_1'));
      const lastframe1 = frames1.length;
      this.lastframe1 = lastframe1;
      this.frames = frames;
      this.resultsReady = true;
    },
    resultsURL(filename) {
      return `${this.baseUrl}results/${filename}?${this.containerSAS}`;
    },

    async fetchOutputJson() {
      const output = this.resultsURL('output.json');
      const response = await fetch(output);
      this.output = await response.json();
    },

    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },

    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // refresh token for images
      await this.fetchMediaInfo();
      // shortcut
      // const { input } = this;
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });
      // request PDF
      const response = await this.$api.call('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: 'sev',
          tokens: [
            ...userContentTokens,
            { key: 'title', type: 'string', data: this.job.name },
            { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
            { key: 'dens', type: 'string', data: this.input.dens },
            { key: 'E_A', type: 'string', data: this.input.E_A },
            { key: 'v_A', type: 'string', data: this.input.v_A },
            { key: 'E_M', type: 'string', data: this.input.E_M },
            { key: 'v_M', type: 'string', data: this.input.v_M },
            { key: 'epsilon_L', type: 'string', data: this.input.epsilon_L },
            { key: 'loading', type: 'string', data: this.input.loading },
            { key: 'sigma_SL', type: 'string', data: this.input.sigma_SL },
            { key: 'sigma_EL', type: 'string', data: this.input.sigma_EL },
            { key: 'unloading', type: 'string', data: this.input.unloading },
            { key: 'sigma_SU', type: 'string', data: this.input.sigma_SU },
            { key: 'sigma_EU', type: 'string', data: this.input.sigma_EU },
            { key: 'crimp_D', type: 'string', data: this.input.crimp_D },
            { key: 'nodes', type: 'string', data: this.output.nodes },
            { key: 'valve_nodes', type: 'string', data: this.output.valve_nodes },
            {
              key: 'hysteresisImage',
              type: 'figure',
              data: {
                url: this.resultsURL('hysteresis.png'),
                caption: 'Radial resistive force and chronic outward force as a function of the diameter size',
              },
            },
            { key: 'maximumForce', type: 'string', data: this.maxForce },
            {
              key: 'firstframe',
              type: 'figure',
              data: {
                url: this.frames[0],
                caption: 'Unloaded valve frame at the beginning of the simulation',
              },
            },
            {
              key: 'lastframe1',
              type: 'figure',
              data: {
                url: this.frames[this.lastframe1],
                caption: 'Valve strains at the end of of Step 1 (radial compression), corresponding to compression at the crimping diameter',
              },
            },
            {
              key: 'lastframe',
              type: 'figure',
              data: {
                url: this.frames.slice(-1)[0],
                caption: 'Valve strains at the end of Step 2 (radial expansion).',
              },
            },
          ],
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'SEV_report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.tabs-with-space {
  margin-top: -0.5em;
  margin-bottom: 2em;
}
.tabs-with-space-above {
  margin-top: 2em;
  margin-bottom: 2em;
}
.figure-with-space {
  max-width: 80%;
  margin: auto;
  margin-bottom: 2em;
}
</style>
